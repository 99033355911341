import React, { Fragment, ReactNode } from 'react';
import clx from 'classnames';

import LoadingIcon from '../Icons/LoadingIcon/LoadingIcon';
import Typography from '../Typography/Typography';

import styles from './Loading.module.css';

export const Types = {
    fullScreen: styles.loadingFullScreen,
    inline: styles.loadingInline,
    none: '',
}

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
    isLoading: boolean;
    type?: keyof typeof Types;
    iconClassName?: string;
    message?: ReactNode;
}

const Loading = ({ isLoading, type = 'none', className, iconClassName, children, message, ...rest }: Props) => {
    const classnames = clx(styles.loading, 'space-y-050', Types[type], className);

    return (
        <Fragment>
            {isLoading ? (
                <div data-testid="loading-icon" className={classnames} {...rest}>
                    <LoadingIcon className={`block mx-auto ${iconClassName}`} />
                    {message ? (
                        <Typography color="quiet" className="block text-center">
                            {message}
                        </Typography>
                    ) : null}
                </div>
            ) : (
                // Fragment is required as multiple children can be passed in.
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <Fragment>{children}</Fragment>
            )}
        </Fragment>
    );
};

export default Loading;
