'use client';

import React, { Fragment } from 'react';

import { useBasketContext } from '@/data/context/basketContext/basketContext';
import { BasketItem as BasketItemType } from '../../types/api/basket/BasketItem';

import Flyout from '../_ui/Flyout/Flyout';
import BasketSummary from './BasketPreview/BasketSummary/BasketSummary';
import Loading from '../_ui/_blocks/Loading/Loading';
import BasketEmpty from './BasketEmpty/BasketEmpty';
import BasketItem from './BasketList/BasketItem/BasketItem';
import IsBasketProcessing from './IsBasketProcessing/IsBasketProcessing';

import styles from './Basket.module.css';

interface Props {
    minimumDeliveryCost: number;
}

const Basket = ({ minimumDeliveryCost, }: Props) => {
    // @ts-expect-error
    const { basket, basketIsOpen, setBasketIsOpen, initialLoadComplete } = useBasketContext();

    const items: BasketItemType[] = basket && basket.items ? basket.items : [];

    const basketSubtotal = basket?.total || 0;

    const handleBasketClose = () => setBasketIsOpen(false);

    return (
        <Flyout isOpen={basketIsOpen} onClose={handleBasketClose} title="Your basket">
            <div data-testid="saved-basket-preview" className="flex flex-col h-full justify-center">
                <IsBasketProcessing>
                    <Loading isLoading={!initialLoadComplete} className="flex-grow">
                        {items.length ? (
                            <Fragment>
                                <ul className={styles.list}>
                                    {items.map((item) => <BasketItem key={item.id} item={item} />)}
                                </ul>
                                <BasketSummary
                                    minimumDeliveryCost={minimumDeliveryCost}
                                    basketSubtotal={basketSubtotal}
                                    onClose={handleBasketClose}
                                />
                            </Fragment>
                        ) : (
                            <BasketEmpty
                                closeBasket={handleBasketClose}
                            />
                        )}
                    </Loading>
                </IsBasketProcessing>
            </div>
        </Flyout>
    );
};

export default Basket;
