import assertValue from '../../utils/assertValue';

export const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION || '2024-03-19'

export const dataset = assertValue(
    process.env.NEXT_PUBLIC_SANITY_DATASET,
    'Missing environment variable: NEXT_PUBLIC_SANITY_DATASET',
);

export const projectId = assertValue(
    process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    'Missing environment variable: NEXT_PUBLIC_SANITY_PROJECT_ID',
);

// If production then use the cdn
// This will either use the CDN or the API for requesting. Mutation's bust the api cdn
export const useCdn = true;
